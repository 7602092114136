//
//
//
//
//
//
//

  import NormalHead from '@/views/components/ishop/header.vue'
  export default {
    name: 'map',
    components: {
      NormalHead,
    },
    created: function () {
      this.x = this.$route.query.X
      this.y = this.$route.query.Y
      this.n = this.$route.query.fullName
      this.src = 'http://apis.map.qq.com/tools/routeplan/eword=' + this.n + '&epointx=' + this.x + '&epointy=' + this.y + '?referer=myapp&key=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77'
    },
    methods: {

    },
    data: function () {
      return {
        headValue: {
          name: "地图",
          haveBack: true,
        },
      }
    }
  }
