//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	import { mapMutations } from 'vuex'
	export default {
		props: ['headValue'],
		methods: {
			...mapMutations([
				'changeisDisable'
			]),
			back: function() {
				let routerPath = '';
				if(this.headValue.name == "我的推荐餐厅") {
					routerPath = JSON.parse(sessionStorage.getItem('isNeedHospital')) ? '/hospital' : '/chooseType'
					this.$router.push({
						path:routerPath
					})
				} else if(this.headValue.name == "首页") {
					this.$router.push('/city')
				} else if(this.headValue.name == "我推荐的外卖/茶歇") {
					routerPath = JSON.parse(sessionStorage.getItem('isNeedHospital')) ? '/hospital' : '/chooseType';
					this.$router.push({
						path:routerPath
					})
				} else if(this.headValue.name == "自定义餐厅") {
					this.changeisDisable(false)
					var openPropu = false
					this.Bus.$emit('openPropu', openPropu);
				} else if(this.headValue.name == "" && this.headValue.webView) {
					this.closeWebView();
				} else {
					this.$router.go(-1);
				}
			},
			lookRule: function() {
				this.messageBox({
					title: '餐厅上线基本规则',
					// message: `1、距离医院3km内<br/>2、能够开具正规发票<br/>3、同意发票暂存在餐厅<br/>4、有包房或独立隔断区域<br/>5、仅接受机打水单或小票`,
					message: this.$store.state.ishop.shopRuleMsg,
					confirmButtonText: '已知晓'
				});
			},
			changePropu() {
				if(this.headValue.name == "已选择餐厅") {
					this.changeisDisable(false)
					var changePopup = false
					this.Bus.$emit('changePopup', changePopup);
				} else if(this.headValue.name == "餐厅") {
					var Stuted = false
					this.Bus.$emit('changeStuted', Stuted);
				} else if(this.headValue.name == "国家") {
					var popupCountry = false
					this.Bus.$emit('changeCountry', popupCountry);
				} else if(this.headValue.name == "省份") {
					var popupProvince = false
					this.Bus.$emit('changeProvince', popupProvince);
				} else if(this.headValue.name == "城市") {
					var popupCity = false
					this.Bus.$emit('changeCity', popupCity);
				} else if(this.headValue.name == "医院") {
					let popupHospital = {
						pageIndex: 1,
						pageSize: 20,
						popupHospital: false
					}

					this.Bus.$emit('changeHospital', popupHospital);
				}
			},
			searchList() {
				this.Bus.$emit('keywordSearchList', this.keyword);
			},
			searchButton(e) {
				var keyCode = e.keyCode
				if(keyCode == '13') {
					e.preventDefault();
					this.Bus.$emit('keywordSearchList', this.keyword);
				}
			},
			jump() {},
			//关闭webview
			closeWebView() {
				if(window.webView) {
					window.webView.close();
				}
			},
		},
		data() {
			return {
				keyword: ''
			}
		},
	}
